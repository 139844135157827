import { MutationTree } from 'vuex';
import Web3 from 'web3';
import { State, WalletConnectMethod } from '@/store/state';

const MutationCommands = {
  SETUP_WEB3: 'setupWeb3',
  SET_CURRENT_ACCOUNT: 'setupCurrentAccount',
  SET_CURRENT_CHAIN_ID: 'setupCurrentChainId',
  SET_WALLET_CONNECT_METHOD: 'setupWalletConnectMethod',
  SET_LANG: 'setLang',
};

const mutations: MutationTree<State> = {
  setupWeb3(state: State, web3: Web3 | null) {
    state.web3 = web3;
  },

  setupCurrentAccount(state: State, account: string | undefined) {
    state.currentAccount = account;
  },

  setupCurrentChainId(state: State, chainId: number) {
    state.currentChainId = chainId;
  },

  setupWalletConnectMethod(state: State, method: WalletConnectMethod) {
    state.walletConnectMethod = method;
  },

  setLang(state: State, lang: string) {
    state.lang = lang;
  },
};

export {
  MutationCommands,
  mutations,
};
