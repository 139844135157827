import { GetterTree } from 'vuex';
import { State } from './state';

const GetterCommands = {
  GET_WEB3: 'getWeb3',
  GET_CURRENT_ACCOUNT: 'getCurrentAccount',
  GET_CURRENT_CHAIN_ID: 'getCurrentChainId',
  GET_LANG: 'getLang',
};

const getters: GetterTree<State, any> = {
  getWeb3(state: State) {
    return state.web3;
  },

  getCurrentAccount(state: State) {
    return state.currentAccount;
  },

  getCurrentChainId(state: State) {
    return state.currentChainId;
  },

  getLang(state: State) {
    return state.lang;
  },
};

export {
  GetterCommands,
  getters,
};
