import { createI18n } from 'vue-i18n';
import messages from './index';

console.log('messages', messages);

class Lang {
  public code:string;

  public name:string;

  public constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }
}

/**
 *  get default language
 */
export function getDefaultLang(): string {
  const lang = navigator.language || 'en';
  return lang.slice(0, 2);
}

const i18n = createI18n({
  // legacy: false, // you must set `false`, to use Composition API
  locale: getDefaultLang(),
  fallbackLocale: 'en', // set fallback locale
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

export default i18n;

export const languages = [
  new Lang('en', 'English'),
  new Lang('zh', '简体中文'),
];
