import { ActionTree, Commit } from 'vuex';
import Web3 from 'web3';
import { State, WalletConnectMethod } from '@/store/state';
import { MutationCommands } from '@/store/mutations';

const ActionCommands = {
  SETUP_WEB3: 'setupWeb3',
  SET_CURRENT_ACCOUNT: 'setupCurrentAccount',
  SET_CURRENT_CHAIN_ID: 'setupCurrentChainId',
  SET_WALLET_CONNECT_METHOD: 'setupWalletConnectMethod',
};

const actions: ActionTree<State, any> = {
  setupWeb3(context: { commit: Commit }, web3: Web3 | null) {
    context.commit(MutationCommands.SETUP_WEB3, web3);
  },

  setupCurrentAccount(context: { commit: Commit }, account: Web3 | null) {
    context.commit(MutationCommands.SET_CURRENT_ACCOUNT, account);
  },

  setupCurrentChainId(context: { commit: Commit }, chainId: number) {
    context.commit(MutationCommands.SET_CURRENT_CHAIN_ID, chainId);
  },

  setupWalletConnectMethod(context: { commit: Commit }, method: WalletConnectMethod) {
    context.commit(MutationCommands.SET_WALLET_CONNECT_METHOD, method);
  },
};

export {
  ActionCommands,
  actions,
};
