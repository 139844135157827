enum ProjectEnvironment {
  Development = 0,
  Production = 1,
}

function currentEnvironment(): ProjectEnvironment {
  if (process.env.NODE_ENV === 'development') {
    return ProjectEnvironment.Development;
  }
  return ProjectEnvironment.Production;
}

export function prod(): boolean {
  return currentEnvironment() === ProjectEnvironment.Production;
}

export {
  ProjectEnvironment,
  currentEnvironment,
};
