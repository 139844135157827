import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/Index/index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/home/index.vue'),
      },
      {
        path: 'economics',
        name: 'Economics',
        component: () => import('../views/Economics/index.vue'),
      },
      {
        path: 'neco-token',
        name: 'NECOToken',
        component: () => import('../views/Economics/NECO.vue'),
      },
      {
        path: 'n-fish',
        name: 'NFish',
        component: () => import('../views/Economics/NFish.vue'),
      },
      {
        path: 'token-farming',
        name: 'token-farming',
        component: () => import('../views/Farms/index.vue'),
      },
      {
        path: 'nft-farm',
        name: 'nft-farm',
        component: () => import('../views/Farms/NftFarm.vue'),
      },
      {
        path: 'nft-market',
        name: 'nftMarket',
        component: () => import('../views/NFTMarket/index.vue'),
      },
      {
        path: 'roadmap',
        component: () => import('../views/RoadMap/index.vue'),
      },
      {
        path: 'my-nft-list',
        component: () => import('../views/NFTs/MyNFTs/index.vue'),
      },
      {
        path: 'my-nft/:id',
        name: 'MyNFTDetail',
        component: () => import('../views/NFTs/MyNFTs/Detail.vue'),
      },
      {
        path: 'nft-library',
        name: 'NFTLibrary',
        component: () => import(/* webpackChunkName: 'NFTLibrary' */'../views/NFTs/NFTLibrary/index.vue'),
      },
      {
        path: 'nft/:id',
        name: 'NFTDetail',
        component: () => import(/* webpackChunkName: 'NFTLibrary' */'../views/NFTs/NFTLibrary/Detail.vue'),
      },
      {
        path: 'airdrop',
        component: () => import(/* webpackChunkName: 'airdrop' */ '../views/Airdrop/index.vue'),
      },
      {
        path: 'exchange',
        component: () => import(/* webpackChunkName: 'exchange' */ '../views/Exchange/index.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
