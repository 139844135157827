import Web3 from 'web3';
import { getDefaultLang } from '@/language/i18n';

enum WalletConnectMethod {
  Metamask,
  Walletconnect
}

interface State {
  web3: Web3 | null;
  currentAccount: string | undefined;
  currentChainId: number;
  walletConnectMethod: WalletConnectMethod;
  lang: string;
}

const state: State = {
  web3: null,
  currentAccount: undefined,
  currentChainId: 0,
  walletConnectMethod: WalletConnectMethod.Metamask,
  lang: getDefaultLang(),
};

export {
  State,
  state,
  WalletConnectMethod,
};
