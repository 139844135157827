import { addSubscribersForMetamaskEvents, getProvider, setupStore } from '@/common/get-web3';
import {
  getNecoTokenAddress,
  getNewNecoTokenContract,
} from '@/common/contracts/apis/neco-token-api';
// import { getNecoTokenAddress } from '@/common/contracts/apis/old-neco-token-api';

export async function useBSCMainNetwork(): Promise<boolean> {
  const provider = await getProvider();
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x38' }],
    });
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x38',
              chainName: 'BSC Mainnet',
              rpcUrls: ['https://bsc-dataseed1.ninicoin.io'],
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
              },
              blockExplorerUrls: ['https://bscscan.com/'],
            },
          ],
        });
      } catch (addError) {
        return false;
      }
    }
    return false;
  }
  return true;
}

export async function useBSCTestNetwork(): Promise<boolean> {
  const provider = await getProvider();
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x61' }],
    });
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x61',
              chainName: 'BSC Testnet',
              rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
              },
              blockExplorerUrls: ['https://testnet.bscscan.com'],
            },
          ],
        });
      } catch (addError) {
        return false;
      }
    }
    return false;
  }
  return true;
}

export async function addNecoTokenToMetamask(chainId: number): Promise<boolean> {
  const address = getNecoTokenAddress(chainId);
  const provider = await getProvider();
  try {
    await provider.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          symbol: 'NECO',
          decimals: 18,
          image: 'https://raw.githubusercontent.com/Neco-Fun/neco-fun-contracts/main/resources/new_neco_logo.png',
        },
      },
    });
  } catch (switchError) {
    return false;
  }
  return true;
}
