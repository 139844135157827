import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import axios from 'axios';
import NecoToken from '../abis/neco-token';

function getOldNecoTokenAddress(chainId: number): string | undefined {
  return NecoToken.oldNECOaddress.get(chainId);
}

function getNecoTokenAddress(chainId: number): string | undefined {
  return NecoToken.necoAddress.get(chainId);
}

function getOldNecoTokenContract(web3: Web3 | null, chainId: number): Contract | null {
  const address = getOldNecoTokenAddress(chainId);
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(NecoToken.ABI, address);
}

function getNewNecoTokenContract(web3: Web3 | null, chainId: number): Contract | null {
  const address = getNecoTokenAddress(chainId);
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(NecoToken.ABI, address);
}

async function getTotalSupply(web3: Web3, chainId: number): Promise<string> {
  const contract = getNewNecoTokenContract(web3, chainId);
  if (contract === null) {
    return '0';
  }
  return contract.methods.totalSupply().call();
}

async function getBalanceOf(contract: Contract, account?: string): Promise<string> {
  if (account === undefined) {
    console.log('account is ');
    return Promise.resolve('0');
  }
  return contract.methods.balanceOf(account).call();
}

async function approveOldNeco(
  contract: Contract,
  account: string,
  spender: string,
  amount: string,
): Promise<boolean> {
  try {
    await contract.methods.approve(spender, amount).send({ from: account });
    return true;
  } catch (err) {
    return false;
  }
}

export async function getNECOTokenPrice(): Promise<string> {
  const result = await axios.get('https://api.pancakeswap.info/api/v2/tokens/0xd23891FC1A515A88C571064637502e3766819e2d');
  return result.data.data.price;
}

export {
  getOldNecoTokenContract,
  getNewNecoTokenContract,
  getOldNecoTokenAddress,
  getNecoTokenAddress,
  getTotalSupply,
  getBalanceOf,
  approveOldNeco,
};
