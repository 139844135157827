import Web3 from 'web3';
import { Store } from 'vuex';
import { State, WalletConnectMethod } from '@/store/state';
import { ActionCommands } from '@/store/actions';

export function getProvider(): any | null {
  if (window.ethereum) {
    return window.ethereum;
  }
  return null;
}

// isMetamaskConnected Check Metamask is connected.
// The issue here is the provider.isConnected() will return false after reloading page,
// but Metamask has been connected. So we should call getAccounts() to
// check the status of connection.
export async function isMetamaskConnected(): Promise<boolean> {
  const provider = getProvider();
  if (provider) {
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    return accounts.length > 0;
  }
  return false;
}

// setupStore reset the store.
export async function setupStore(
  provider: any | null,
  store: Store<State>,
  walletConnectMethod: WalletConnectMethod,
): Promise<boolean> {
  if (provider === null) {
    return Promise.resolve(false);
  }

  const web3 = new Web3(provider);
  const accounts = await web3.eth.getAccounts();
  // metamask getChainId will return 0x1, web3.eth.getChainId() returns 1.
  const chainId = await web3.eth.getChainId();
  console.log(`web3 is ${web3}`);
  console.log('store is', store.state);
  try {
    await store.dispatch(ActionCommands.SETUP_WEB3, web3);
    await store.dispatch(ActionCommands.SET_CURRENT_ACCOUNT, accounts[0]);
    await store.dispatch(ActionCommands.SET_CURRENT_CHAIN_ID, chainId);
    await store.dispatch(ActionCommands.SET_WALLET_CONNECT_METHOD, walletConnectMethod);
  } catch (error) {
    return Promise.resolve(false);
  }
  return Promise.resolve(true);
}

// addSubscribersForMetamaskEvents. when status of Metamask changed, reload the whole page.
export function addSubscribersForMetamaskEvents(provider: any | null): void {
  if (provider === null) {
    return;
  }

  provider.on('accountsChanged', (accounts: string[]) => {
    if (accounts.length > 0) {
      console.log('Account Changed.');
      window.location.reload();
    }
  });

  provider.on('chainChanged', () => {
    console.log('Network Changed.');
    window.location.reload();
  });

  provider.on('disconnect', () => {
    console.log('Metamask Disconnected.');
    window.location.reload();
  });
}
