import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from 'vue';
import { State, state } from '@/store/state';
import { mutations } from '@/store/mutations';
import { actions } from '@/store/actions';
import { getters } from '@/store/getters';

export default createStore({
  state,
  mutations,
  actions,
  getters,
});

export const key: InjectionKey<Store<State>> = Symbol('vue-store');

export function useStore(): Store<State> {
  return baseUseStore(key);
}
